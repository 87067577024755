/* Set global font size */
html {
  font-size: 16px; /* You can change this to any desired value */
}




/* Navbar styles */
.navbar {
  /* background-image: var(--navbar-background); */
  background-image: radial-gradient(circle, rgba(40,90,175,1) 19%, rgba(56,113,210,1) 100%);
}
.me-auto .nav-link{
 color: #FFFF; 
 /* color: red;  */
}



/* Specific styling for components */
.personal-details p {
  margin-bottom: 10px; /* Adjust the spacing as needed */
  display: flex;
  justify-content: flex-start;
}

.personal-details p strong {
  width: 100px; /* Adjust the width as needed */
}

.social-profiles a {
  margin-right: 10px; /* Adjust the spacing between social icons */
}

.social-icon {
  width: 30px; /* Adjust the size of the social icons */
  height: 30px;
}


/* Day - Night Button */
.icon-button {
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
}


/* Features card */
.features-card{
  background-color: #ffffff;
  /* background-color: var(--card-background-color); */
  color: #333333;
  /* color: var(--card-text-color); */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Standard shadow */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for hover effects */
  border-radius: 8px; /* Optional: Adds rounded corners to the card */
}

.features-card:hover{
  transform: scale(1.05); /* Slightly zooms in the card */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Darker shadow on hover */
}


/* Button styles */
.card-btn{
  /* background-image: var(--card-btn-background-color); */
  background-image: linear-gradient(135deg, #7272ff 10%, #a6fcff 100%);
  color: #333333;
  border: none;
  padding: 8px 16px;
}


/* Login Page */
/* Add this CSS to your stylesheet if needed */
.container-fluid {
  padding: 0;
}

.card {
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.button-primary {
  background-color: #6f52ed;
  border-color: #6f52ed;
}

.button-primary:hover {
  background-color: #5a41c7;
  border-color: #5a41c7;
}

/*Layout Page css  */
/* Layout Wrapper */
.layout-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* Content Area */
.content {
  flex: 1; /* This makes the content area take up the remaining space after the navbar */
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center the content vertically */
  align-items: stretch; /* Stretch the content to full width */
}


/* Login Page Container */
.container-fluid {
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

/* Adjust the height of the rows inside login */
.row {
  height: 100%;
}

.col {
  display: flex;
  justify-content: center;
  align-items: center;
}

.card {
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}



/* User Profile Cover Page */

/* .profile-cover img {
  height: 400px;
  object-fit: fill;
} */

.profile-coverr {
  position: relative;
  width: 100%;
  height: 475px; /* or whatever height you need */
  overflow: hidden; /* Ensure image doesn't overflow outside the container */
  background-color: darkblue;
}

.cover-imagee {
  width: 100%;
  height: 100%;
  object-fit:fill;
   /* Ensures the image covers the div without distortion */
}





/* Card Styling */
.blog-card {
  width: 18rem;
  height: 24rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.card-image {
  height: 12rem;
  object-fit: contain;
}

.card-title {
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  text-overflow: ellipsis;
  overflow: visible;
  
}

.card-date {
  margin-bottom: 0.5rem;
}

.card-summary {
  font-size: 0.875rem;
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5; /* Show 3 lines, then truncate */
  -webkit-box-orient: vertical;
  height: 3rem; /* Adjust height to match the line clamp */
}

.card-btn {
  background-image: linear-gradient(135deg, #7272ff 10%, #a6fcff 100%);
  color: #fff;
  border: none;
  padding: 8px 16px;
  width: 100%;
  text-align: center;
  display: inline-block;
  margin-top: auto;
}

.card-btn:hover {
  background-color: #5a41c7;
}


/* Share Icons */
/* Share Button */
.share-container {
  position: relative;
}

.share-btn {
  position: relative;
}

.share-options {
  position: absolute;
  bottom: 100%; /* Moves the share options above the button */
  right: 0;
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px;
  z-index: 1000; /* Ensure it's above other content */
}

.share-options .btn {
  width: 100%;
  text-align: center;
  padding: 10px;
}

.share-options .btn-link {
  color: #333;
}

.share-options .btn-link:hover {
  color: #007bff; /* Optional: Change color on hover */
}


.login-image {
  /* background-image: var(--navbar-background); */
  background-image:  linear-gradient(135deg, #5151E5 10%, #72EDF2 100%);;
  background-size: 400% 400%;
  animation: gradientAnimation 5s ease infinite;
}


@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}


/* Proifle Image  */
.profile-img {
  height: auto;
  max-height: 900px; /* Default size for larger screens */
}


/* Profile Image show */
.custom-shadow {
  box-shadow: 0 4px 8px rgba(17, 255, 0, 0.5); /* Example: Red shadow */
}


/* ******************************** */
/* Gardiant Border For the card */
/* ******************************** */
.bgblogs{
  background-color: red;
}




@media (max-width: 992px) {
  .profile-img {
    max-height: 270px; /* Slightly smaller on medium screens */
  }
}

@media (max-width: 767px) {
  .text-start {
    text-align: center !important;
  }
  .profile-img {
    max-height: 250px; /* Smaller on small screens */
  }
  .profile-coverr {
    position: relative;
    width: 100%;
    height: auto; /* or whatever height you need */
    overflow: hidden; /* Ensure image doesn't overflow outside the container */
    background-color: darkblue;
  }
}
